<template>
	<FormsSelect
		v-model="computedModelValue"
		type="combobox"
		:required="required"
		:error-label="type"
		field-label="name"
		:name="name ?? type"
		:placeholder="placeholder"
		:error="error"
		:options="computedOptions"
		@focus="$emit('focus')"
		@search="getOptions"
	>
		<template #label>
			{{ type }}
			<a v-if="lookupUrl" tabindex="-1" :href="lookupUrl" target="_blank" class="float-right font-normal text-jmi-purple3 text-sm hover:text-jmi-purple2">
				Lookup
				<ArrowTopRightOnSquareIcon class="h-4 w-4 stroke-1 inline" aria-hidden="true" />
			</a>
		</template>
	</FormsSelect>
</template>
<script setup lang="ts">
import {useSuppliers} from "~/stores/suppliers";
import {
	ArrowTopRightOnSquareIcon,
} from '@heroicons/vue/24/outline';
import type { SupplierRecord, SupplierType } from "~/utils/types";
import {computed, ref, onBeforeMount, watch} from "vue";

const props = withDefaults(defineProps<{
	type: SupplierType,
	name?: string | null,
	error?: string | string[] | null,
	modelValue?: number | null,
	placeholder?: string,
	required?: boolean | string,
}>(), {
	name: null,
	error: null,
	modelValue: null,
	placeholder: "Unknown",
	required: false,
});

const emit = defineEmits(['update:modelValue', 'focus']);

const store = useSuppliers();

const lookupUrl = store.lookupUrls[props.type];

const computedModelValue = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	}
});

let useSingleRecord = ref(props.modelValue !== null);

const getSelectedRecordData = async () => {
	let instance_name = "search-single-" + props.type;
	let has_existing_value = false;

	if (props.modelValue) {
		store.instances["search-existing-" + props.type]?.queryData.forEach((record) => {
			if (record.id === props.modelValue) {
				has_existing_value = true;
			}
		});

		if (has_existing_value) {
			useSingleRecord.value = false;
		} else {
			useSingleRecord.value = true;
			store.shutdown(instance_name);
			await store.boot(instance_name, {
				perPage: 1,
				useGlobalCounts: false,
				queryFacets: {
					id: props.modelValue
				}
			});
		}
	} else {
		useSingleRecord.value = false;
	}
};
watch(() => props.modelValue, () => {
	getSelectedRecordData();
});

onBeforeMount(() => {
	store.boot("search-existing-" + props.type, {
		useCachedFacets: false,
		perPage: 50,
		useGlobalCounts: false,
		queryFacets: {
			types: props.type
		},
		sort: {'name':'desc'},
	});

	if (props.modelValue) {
		getSelectedRecordData();
	}

});

const lastSearch = ref(null as null | string);

const runSearch = (search: string) => {
	if (lastSearch.value !== search && search !== props.placeholder) {
		useSingleRecord.value = false;
		store.search("search-existing-" + props.type, search);
		lastSearch.value = search == null || search.length === 0 ? 'all' : search;
	}
}

const computedOptions = computed(() => {
	const search = store.instances["search-existing-" + props.type]?.queryData ?? [];
	const searchSingle = store.instances["search-single-" + props.type]?.queryData;

	if (search.length > 0 || (searchSingle && searchSingle.length > 0)) {
		// merge the two arrays
		let merged = search.concat(searchSingle ?? []);
		// remove duplicates
		return merged.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
	}

	return [];
});

const getOptions = (search: string | undefined): SupplierRecord[] => {
	runSearch(search ?? "");
	return computedOptions.value;
};

</script>
